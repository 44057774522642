/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveMeta } from "@dataResolvers";
import { Visit as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query {
    craft {
      entry(section: "visit") {
        ... on Craft_visit_visit_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          heroHeading
          heroSubheading
          heroBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity
          mediaUrl
          # closed
          subheading
          fullWidthText
          linkList {
            ... on Craft_linkList_button_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
            ... on Craft_linkList_link_BlockType {
              uid
              type: typeHandle
              linkObj {
                text
                url
              }
            }
          }
          # reservations
          reservationsBlockLeft {
            ... on Craft_reservationsBlockLeft_reservationsBlockLeftContent_BlockType {
              reservationsBlockLeftTitle
              reservationsBlockLeftBlurb
              weekdayHoursTitle
              weekdayHours
              weekendHolidayHoursTitle
              weekendHours
            }
          }
          reservationsBlockRight {
            ... on Craft_reservationsBlockRight_reservationsBlockRightContent_BlockType {
              headingImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              scrimOpacity
              heading
              blurb
              buttonOne {
                text
                url
              }
              buttonTwo {
                text
                url
              }
              linkOne {
                text
                url
              }
              additionalContent
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query {
  craft {
    entry(section: "visit") {
      ... on Craft_visit_visit_Entry {
        uid
        heroHeading
        heroSubheading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity
        mediaUrl
        # closed
        subheading
        fullWidthText
        linkList {
          ... on Craft_linkList_button_BlockType {
            uid
            type: typeHandle
            linkObj {
              text
              url
            }
          }
          ... on Craft_linkList_link_BlockType {
            uid
            type: typeHandle
            linkObj {
              text
              url
            }
          }
        }
        # reservations
        reservationsBlockLeft {
          ... on Craft_reservationsBlockLeft_reservationsBlockLeftContent_BlockType {
            reservationsBlockLeftTitle
            reservationsBlockLeftBlurb
            weekdayHoursTitle
            weekdayHours
            weekendHolidayHoursTitle
            weekendHours
          }
        }
        reservationsBlockRight {
          ... on Craft_reservationsBlockRight_reservationsBlockRightContent_BlockType {
            headingImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            scrimOpacity
            heading
            blurb
            buttonOne {
              text
              url
            }
            buttonTwo {
              text
              url
            }
            linkOne {
              text
              url
            }
            additionalContent
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    heroHeading,
    heroBackgroundImage,
    heroSubheading,
    subheading,
    fullWidthText,
    linkList,
    reservationsBlockLeft,
    reservationsBlockRight,
    scrimOpacity,
    mediaUrl,
  } = craft.entry;

  const left = reservationsBlockLeft[0];
  const right = reservationsBlockRight[0];

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      content: heroSubheading,
      image: resolveImage(heroBackgroundImage),
      scrimOpacity,
    },
    mediaUrl,
    closed: {
      heading: subheading,
      content: fullWidthText,
      links: linkList.map(link => {
        return {
          ...link,
          link: link.linkObj,
        };
      }),
    },
    reservations: {
      heading: left.reservationsBlockLeftTitle,
      content: left.reservationsBlockLeftBlurb,
      times: {
        wdHeading: left.weekdayHoursTitle,
        wdCopy: left.weekdayHours,
        weHeading: left.weekendHolidayHoursTitle,
        weCopy: left.weekendHours,
      },
      visits: {
        headline: right.heading,
        subhead: right.blurb,
        content: {
          image: resolveImage(right.headingImage),
          scrimOpacity: right.scrimOpacity,
          text: right.additionalContent,
          buttons: [right.buttonOne, right.buttonTwo],
          youtube: right.linkOne,
          // donate: {
          //   text: "Donate to Support the Temple",
          //   url: "/",
          // },
        },
      },
    },
    // reservations:
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
